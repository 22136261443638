<template>
  <div>
    <Divider dashed style="color:#fff">设置任务内容</Divider>
    <Form
      ref="newTaskData"
      :model="newTaskData"
      :rules="ruleValidate"
      label-position="top"
    >
      <FormItem
        label="任务类型"
        prop="taskitemType"
        v-if="taskitemTypeArray.length>1"
      >
        <Select
          v-model="newTaskData.taskitemType"
          size="small"
          placeholder="任务类型"
        >
          <Option
            v-for="item in taskitemTypeArray"
            :key="'taskType_'+item.value"
            :value="item.value"
          >{{item.name}}</Option>
        </Select>
      </FormItem>
      <FormItem
        label="作业日期"
        prop="actionDate"
      >
        <DatePicker
          placeholder="选择作业日期"
          v-model="newTaskData.actionDate"
          size="small"
          type="date"
          style="width:100%"
          :editable="false"
          :clearable="false"
          @on-change="changeActionDate"
        ></DatePicker>
      </FormItem>
      <slot name="extra">

      </slot>
      <FormItem label="备注">
        <Input
          size="small"
          type="textarea"
          :rows="3"
          v-model.trim="newTaskData.remarks"
          placeholder="备注"
        />
      </FormItem>
      <FormItem>
        <div class="text-right">
          <Button
            size="small"
            type="success"
            :disabled="selectedResourceIdArray.length===0"
            :loading="btnSetLoading"
            @click="handleSubmit"
          >确认设置</Button>
        </div>

      </FormItem>
    </Form>
  </div>
</template>

<script>
import { getTaskitemTypeList, setTaskitem, updateTaskitem, setStationTaskitem } from '@/api/msp/demandV3'
export default {
  data () {
    return {
      taskitemTypeArray: [],
      newTaskData: {
        taskitemType: null,
        actionDate: '',
        deviceIds: '',
        taskId: null,
        extras: '',
        productId: null,
        remarks: ''
      },
      ruleValidate: {
        actionDate: [{ required: true, message: ' ', trigger: 'change' }],
        taskitemType: [{ required: true, type: 'number', message: ' ', trigger: 'change' }]
        // name: [{ required: true, type: 'string', message: '请填写任务名称', trigger: 'blur' }]
      },
      btnSetLoading: false
    }
  },
  computed: {
    curDemandType () {
      return this.$store.state.task.curDemandType
    },
    curTaskId () {
      return this.$store.state.task.curTaskId
    },
    opreateType () {
      return this.$store.state.task.opreateType
    },
    dataSource () {
      return this.$store.state.task.dataSource
    },
    selectedResourceIdArray: {
      get () {
        return this.$store.state.task.selectedResourceIdArray
      },
      set (val) {
        this.$store.commit('set_selected_resourceId_array', val)
      }
    },
    selectedTaskitemIdArray: {
      get () {
        return this.$store.state.task.selectedTaskitemIdArray
      },
      set (val) {
        this.$store.commit('set_selected_taskitemid_array', val)
      }
    }
  },
  created () {
    this.getTaskitemTypeData()
  },
  methods: {
    getTaskitemTypeData () {
      getTaskitemTypeList({ tasktype: this.curDemandType }).then(res => {
        if (res && !res.errcode) {
          this.taskitemTypeArray = res
          if (this.taskitemTypeArray.length > 0) {
            this.newTaskData.taskitemType = this.taskitemTypeArray[0].value
          }
        } else {
          this.taskitemTypeArray = []
        }
      })
    },
    changeActionDate (date) {
      this.newTaskData.actionDate = date
    },
    handleSubmit () {
      this.$refs.newTaskData.validate(valid => {
        if (valid) {
          this.btnSetLoading = true
          switch (this.curDemandType) {
            case 8:
              this.handleSetInspectionTask()
              break
            case 9:
              this.handleSetMaintainTask()
              break
            default:
              this.handleSetMaintainTask()
              break
          }
        }
      })
    },
    /**
     * 处理设置维护任务提交
     */
    handleSetMaintainTask () {
      if (this.opreateType === 1) {
        this.newTaskData.taskId = this.curTaskId
        this.newTaskData.deviceIds = JSON.stringify(this.selectedResourceIdArray)
        setTaskitem(this.newTaskData).then(res => {
          this.btnSetLoading = false
          if (res && res.errcode === 0) {
            this.selectedResourceIdArray = []
            this.selectedTaskitemIdArray = []
            this.$store.commit('set_temp_selected_item', null)
            if (this.dataSource === 1) {
              // 重新获取库存资源
              this.$store.dispatch('getDeviceData')
            } else {
              // 重新获取上报资源
              this.$store.dispatch('getTroubleData')
            }
            this.$Notice.success({ desc: '操作成功' })
          }
        })
      } else {
        this.newTaskData.taskitemIds = JSON.stringify(this.selectedTaskitemIdArray)
        updateTaskitem(this.newTaskData).then(res => {
          this.btnSetLoading = false
          if (res && res.errcode === 0) {
            this.selectedResourceIdArray = []
            this.selectedTaskitemIdArray = []
            this.$store.commit('set_temp_selected_item', null)
            this.$store.dispatch('getTaskitemData')
            this.$Notice.success({ desc: '操作成功' })
          }
        })
      }
    },
    /**
     * 处理设置巡检任务提交
     */
    handleSetInspectionTask () {
      if (this.opreateType === 1) {
        this.newTaskData.taskId = this.curTaskId
        this.newTaskData.stationIds = JSON.stringify(this.selectedResourceIdArray)
        setStationTaskitem(this.newTaskData).then(res => {
          this.btnSetLoading = false
          if (res && res.errcode === 0) {
            this.selectedResourceIdArray = []
            this.selectedTaskitemIdArray = []
            this.$store.commit('set_temp_selected_item', null)
            this.$store.dispatch('getStationData')
            this.$Notice.success({ desc: '操作成功' })
          }
        })
      } else {
        this.newTaskData.taskitemIds = JSON.stringify(this.selectedTaskitemIdArray)
        updateTaskitem(this.newTaskData).then(res => {
          this.btnSetLoading = false
          if (res && res.errcode === 0) {
            this.selectedResourceIdArray = []
            this.selectedTaskitemIdArray = []
            this.$store.commit('set_temp_selected_item', null)
            this.$store.dispatch('getTaskitemData')
            this.$Notice.success({ desc: '操作成功' })
          }
        })
      }
    }
  }
}
</script>
